.map {
    background-color: #ffffff;
    padding: 1rem;
    margin: 2rem auto;
    width: 70rem;
    max-width: 95%;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  }
  
.tooltip {
  font-size: 40px;
}